@import url('https://cdn.jsdelivr.net/gh/sunn-us/SUITE/fonts/static/woff2/SUITE.css');

html {
    margin: 0;
    padding: 0;
    cursor: none;
}

body{
    -ms-overflow-style: none;
    margin: 0;
    padding: 0;
    }
    position: absolute;
    top: 0;
   ::-webkit-scrollbar {
     display: none;
}

.white {
    background-color: white;
}

.black {
    background-color: black;
}

section {
    height: 100vh;
}

#nametag {
    position: fixed;
    font-weight: 100;
    left: 1vh;
    top: 1vh;
    z-index: 100;
    margin: 0;
    text-shadow: 
        -1px -1px 0 white,   /* 위와 왼쪽 */
         1px -1px 0 white,   /* 위와 오른쪽 */
        -1px  1px 0 white,   /* 아래와 왼쪽 */
         1px  1px 0 white;
}

.fullpage {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
}

.TitlePage {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    display: flex;
}

#black {
    background-color: black;
}

.cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

footer {
    bottom: 0;
    width: 100vw;
    margin-top: 200px;
    padding-top: 80px;
}