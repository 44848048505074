#maintitle {
    width: fit-content;
    position: absolute;
    top: 30vh;
    margin-bottom: 20vh;
    font-size: 6.5rem;
    font-family: 'SUITE', sans-serif;
    animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    border-right: 10px rgba(0, 0, 0, 0) solid;
  }
  50% {
    border-right: 10px rgba(0, 0, 0, 1) solid;
  }
  100% {
    border-right: 10px rgba(0, 0, 0, 0) solid;
  }
}

.arrows {
  position: absolute;
  top: 70vh;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
}

.arrows:before {
  content: "";
  position: absolute;
  width: 80%;
  height: 80%;
  border-left: 26px solid rgba(0, 0, 0, 0.7);
  border-bottom: 26px solid rgba(0, 0, 0, 0.7);
  transform: translate(0px, 106px) rotate(-45deg); /* Adjusted the translate values */
  animation: arrows 3s linear infinite;
}

.arrows:after {
  content: "";
  position: absolute;
  width: 80%;
  height: 80%;
  border-left: 26px solid rgba(0, 0, 0, 0.7);
  border-bottom: 26px solid rgba(0, 0, 0, 0.7);
  transform: translate(27px, 0px) rotate(-45deg); /* Adjusted the translate values */
  animation: arrows 3s linear infinite -1.5s;
}

@keyframes arrows {
  0% {
    border-left: 26px solid rgba(0, 0, 0, 0);
    border-bottom: 26px solid rgba(0, 0, 0, 0);
    transform: translate(-13px, -53px) rotate(-45deg);
  }
  10%, 90% {
    border-left: 26px solid rgba(0, 0, 0, 0);
    border-bottom: 26px solid rgba(0, 0, 0, 0);
  }
  50% {
    border-left: 26px solid rgba(0, 0, 0, 0.7);
    border-bottom: 26px solid rgba(0, 0, 0, 0.7);
    transform: translate(-13px, 0px) rotate(-45deg);
  }
  100% {
    border-left: 26px solid rgba(0, 0, 0, 0);
    border-bottom: 26px solid rgba(0, 0, 0, 0);
    transform: translate(-13px, 53px) rotate(-45deg);
  }
}
