#contacttitle {
    color: white;
    font-family: 'SUITE', sans-serif;
    font-size: 80px;
    position: relative;
    top: 8vh;
}

#contactsubtitle {
    color: white;
    font-family: 'SUITE', sans-serif;
    font-size: 30px;
    padding-left: 5vw;
    padding-right: 5vw;
}

#contactcontent {
    color: white;
    font-family: 'SUITE', sans-serif;
    font-size: 20px;
    padding-left: 5vw;
    padding-right: 5vw;
}

#contactcontainer {
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

footer {
    color: white;
    margin-top:10vh;
}

#githubicon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}