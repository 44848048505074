#skilltitle {
    color: white;
    font-family: 'SUITE', sans-serif;
    font-size: 80px;
}

#reacticon {
    width: 40vw;
    position: absolute;
    right: -10vw;
}

#fastapiicon {
    width: 30vw;
    position: absolute;
    left: 0vw;
}

#nodejsicon {
    width: 15vw;
    position: absolute;
    left: 60vw;
}